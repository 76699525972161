<template>
  <div class="Counselor bg-white">
    <Row class="px-1">
      <i-col span="20" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="AddSurvey"></Button>
        <i-input
          v-model="inputValue"
          size="large"
          style="width: 15rem"
          class="el-inline mr-1"
          placeholder="请输入问卷名称进行查询"
        >
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="HandleSearch"></Button>
        </i-input>
        <Button size="large" class="mr-1" @click="batchRemove">批量删除</Button>
        <Button size="large" @click="batchExport">批量导出</Button>
      </i-col>
      <i-col span="4" class="align-right colHeigth">
        <Checkbox @on-change="changeStatus">被停用问卷</Checkbox>
      </i-col>
      <i-col span="24">
        <Table
          border
          stripe
          ref="surveyTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          :height="theight"
          class="mt-1"
        >
          <div slot-scope="{row}" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 0.25rem"
              @click="getSurveyDetail(row)"
            >详情</Button>
            <Button
              type="info"
              size="small"
              style="margin-right: 0.25rem"
              @click="exportSurvey(row)"
            >导出</Button>
            <Button type="warning" size="small" @click="removeSurvey(row)">删除</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "Counselor",
  data() {
    return {
      openstate: false,
      dateoptions: {
        shortcuts: [
          {
            text: "明天",
            value() {
              const date = new Date();
              var y = date.getFullYear();
              var m = date.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              var d = date.getDate() + 1;
              d = d < 10 ? "0" + d : d;
              console.log(y + "-" + m + "-" + d);
              return y + "-" + m + "-" + d + "00:00:00";
            },
          },
        ],
      },
      timePickerOptions: { steps: [1, 60] },
      tData: [],
      inputValue: "",
      status: 1,
      searching: true,
      theight: window.innerHeight - 175,
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "问卷名称",
          key: "surveyName",
          minWidth: 50,
        },
        {
          title: "使用次数",
          key: "useTimes",
          minWidth: 50,
        },
        {
          title: "使用状态",
          minWidth: 60,
          align: "center",
          render: (h, params) => {
            return h("i-switch", {
              props: {
                size: "large",
                value: params.row.isOpen === 1,
                "true-color": "#5cadff",
              },
              scopedSlots: {
                open: () => h("span", "开启"),
                close: () => h("span", "关闭"),
              },
              on: {
                "on-change": (value) => {
                  this.changeUseState(params.row, value);
                },
              },
            });
          },
        },
        {
          title: "问卷说明",
          key: "surveyHint",
          minWidth: 500,
          render: (h, params) => {
            return h("div", {
              domProps: {
                innerHTML: `${params.row.surveyHint}`,
              },
            });
          },
          ellipsis: true,
        },
        {
          title: "发布时间",
          key: "createTime",
          minWidth: 100,
          render: (h, params) => {
            let dt = this.myjs.subDate(params.row.createTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      checktime: "",
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.getSurveyList();
  },
  methods: {
    batchRemove() {
      console.log();
    },
    batchExport() {
      let arr = [];
      arr = this.$refs.surveyTable.getSelection();
      let ids = "";
      for (let i = 0; i < arr.length; i++) {
        ids += arr[i].surveyId + ",";
      }
      console.log(ids);
    },
    instance(id, endtime, type) {
      const title = "警告";
      const content =
        "点击清空会将截止时间设置为长期，截止时间显示为2999-01-01";
      this.$Modal.warning({
        title: title,
        content: content,
        onOk: () => {
          this.changeEndTime(id, endtime, type);
        },
      });
    },
    selecttime(e) {
      this.checktime = e;
      console.log(e);
    },
    changeEndTime(id, endtime, type) {
      if (type == 0) {
        endtime = "2999-01-01 00:00:00";
      }
      endtime = endtime.replace(" ", "T");
      console.log(id, endtime);
      this.$post("Survey/ChangeSurveyEndTime", {
        surveyId: id,
        endTime: endtime,
      }).then((res) => {
        console.log(res);
        if (res.data.code) {
          this.$Message.success({
            content: "更改成功",
            duration: 3,
          });
          this.getSurveyList();
        } else {
          this.$Message.error({
            content: "更改失败，请稍后重试",
            duration: 2,
          });
        }
      });
    },
    changeUseState(row, value) {
      if (value) {
        value = 1;
      } else {
        value = 0;
      }
      this.$post("Survey/ChangeSurveyState", {
        surveyId: row.surveyId,
        isOpen: value,
      }).then((res) => {
        console.log(res);
        if (res.data.code) {
          this.$Message.success({
            content: "更改成功",
            duration: 3,
          });
        } else {
          this.$Message.error({
            content: "更改失败，请稍后重试",
            duration: 2,
          });
        }
      });
    },
    AddSurvey() {
      this.$router.push({ name: "AddSurvey" });
    },
    removeSurvey(row) {
      if (row.isOpen) {
        this.$Message.error({
          content: "此问卷正在使用，禁止删除",
          duration: 3,
        });
      }
    },
    exportSurvey(row) {},
    getSurveyDetail(row) {
      this.$router.push({
        path: "SurveyDesign",
        query: { surveyId: row.surveyId },
      });
    },
    getSurveyList() {
      this.searching = false;
      this.$get("Survey/GetSurveyList", {
        status: this.status,
        keyword: this.inputValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.recordTotal = res.data.total;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
      });
    },
    HandleSearch() {
      this.getSurveyList();
    },
    changeSize(e) {
      this.pageSize = e;
      this.getSurveyList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getSurveyList();
    },
    changeStatus(status) {
      if (status == false) {
        this.status = 1;
      } else {
        this.status = 0;
      }
      this.getSurveyList();
    },
  },
};
</script>

<style>
</style>
